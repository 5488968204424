<template>
    <div class="home-rotation">
        <info-table class="info-table" :selection="false" :tableHeadConfig="tableHeadConfig" :tableLoadData="tableLoadData" :tableLoading="tableLoading">
            <template v-slot:sort="slotData">
                <div class="sort-sty">{{ slotData.data.row.sort }}</div>
            </template>
            <template v-slot:thumbnail="slotData">
                <img class="thumbnail-img" :src="slotData.data.row.url" />
            </template>
            <template v-slot:operation="slotData">
                <div class="oper-del" @click="delInfo(slotData.data.row)">删除</div>
                <div class="oper-modify" @click="modifyInfo(slotData.data.row)">修改</div>
            </template>
        </info-table>
        <div class="e-pagination">
            <infoPage :pagination="pagination" @pageclcik="pageclcik"></infoPage>
        </div>

        <deldialog :dialogVal="delDialog" title="您确定要删除该轮播图吗?" :appId="rotationId" @dialogChange="delChange"></deldialog>
    </div>
</template>

<script>
import deldialog from "@/components/dialog/deldialog.vue";
import { encrypt } from "@/utils/encry.js";
import { sessionGetKey } from "@/utils/sessionStorage.js";
export default {
    components: {
        deldialog,
    },
    props: {
        title: {
            type: String,
            default: "首页轮播图",
        },
    },
    mounted() {
        this.platformId = sessionGetKey("platformId");
        this.getHome();
    },
    data() {
        return {
            tableLoading: false,
            delDialog: false,
            rotationId: "", //要删除的轮播图ID
            pagination: {
                currentpage: 1,
                total: 1,
                totalpage: 1,
                pageSize: 10,
            },
            platformId: "",
            tableHeadConfig: [
                {
                    label: "排序",
                    field: "sort",
                    sort: true,
                    columnType: "slot",
                    slotName: "sort",
                },
                {
                    label: "ID",
                    field: "id",
                    sort: true,
                },
                {
                    label: "缩略图",
                    columnType: "slot",
                    slotName: "thumbnail",
                    width: 600,
                },
                {
                    label: "操作",
                    columnType: "slot",
                    slotName: "operation",
                },
            ],
            tableLoadData: [],
        };
    },
    methods: {
        // 点击删除
        delInfo(row) {
            this.delDialog = true;
            this.rotationId = row.id;
        },
        delChange(type, id) {
            const that = this;
            console.log(type, id, "111");
            this.delDialog = false;
            if (type == 1) {
                that.$request({
                    url: that.$api.rotantion.delRotan,
                    method: "post",
                    data: {
                        platform_id: that.platformId,
                        type: 0,
                        idp: id,
                    },
                    params: {
                        platform_id: that.platformId,
                    },
                }).then((res) => {
                    that.getHome();
                });
            }
        },
        // 点击修改
        modifyInfo(row) {
            const qer = encrypt(row);
            this.$router.push({ path: "/rotation/edit", query: { data: qer, title: this.title, type: 0 } });
        },
        // 点击页码
        pageclcik(e) {
            this.pagination.currentpage = e;
            this.getHome();
        },
        // 获取首页轮播图
        getHome() {
            const that = this;
            that.tableLoading = true;
            that.$request({
                url: that.$api.rotantion.getRotan,
                method: "post",
                data: {
                    type: 0,
                    page: that.pagination.currentpage,
                },
                params: {
                    platform_id: that.platformId,
                },
            }).then((res) => {
                that.tableLoading = false;
                that.pagination.currentpage = res.data.page;
                that.pagination.total = res.data.all_count;
                that.pagination.totalpage = res.data.all_page;
                if(res.data.d.length !== 0){
                    that.tableLoadData = []
                    res.data.d.forEach((item, index) => {
                        that.tableLoadData.push(JSON.parse(item));
                    });
                }
                console.log(that.tableLoadData, "data");
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.home-rotation {
    text-align: center;
}

.info-table {
    min-height: 400px;
    .sort-sty {
        padding: 0 15px;
        display: inline-block;
        border: 1px solid #333333;
    }

    .thumbnail-img {
        max-width: 150px;
        height: 70px;
    }

    .oper-del {
        display: inline-block;
        width: 80px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background-color: #fe725c;
        color: #ffffff;
        border-radius: 6px;
        cursor: pointer;
    }

    .oper-modify {
        margin-left: 10px;
        display: inline-block;
        width: 80px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background-color: #01d0b5;
        color: #ffffff;
        border-radius: 6px;
        cursor: pointer;
    }
}

.e-pagination {
    margin-top: 24px;
}
</style>
