<template>
    <div class="main-pdding">
        <div class="main-box rotation">
            <div class="main-between">
                <div class="title-tabs">
                    <label v-for="(item,index) in tabsData" :key="index" :class="{'select-tab':selectTab===item.id}"
                        @click="tabInfo(item.id)">{{item.title}}</label>
                </div>
                <div class="new-ppt">
                    <el-button type="primary" icon="el-icon-plus" @click="goAdd()">新增幻灯片</el-button>
                </div>
            </div>
            <div class="rotation-box">
                <homeRotation :title="labelTitle" v-if="selectTab===0"></homeRotation>
                <productRotation :title="labelTitle" v-else-if="selectTab===1"></productRotation>
            </div>
        </div>
    </div>
</template>

<script>
    import homeRotation from './component/homeRotation/homeRotation.vue'
    import productRotation from './component/productRotation/productRotation.vue'

    export default {
        components: {
            homeRotation,
            productRotation
        },
        data() {
            return {
                selectTab: 0,
                tabsData: [{
                        id: 0,
                        title: '首页轮播图'
                    },
                    {
                        id: 1,
                        title: '产品轮播图'
                    }
                ],
                labelTitle:''
            }
        },
        watch:{
            // 侦听切换
            selectTab:{
                handler:function(newVal,oldVal){
                    this.labelTitle = this.tabsData[newVal].title
                },
                immediate:true
            },
        },
        methods: {
            // 点击切换
            tabInfo(id) {
                this.selectTab = id
            },
            // 跳转到添加页面
            goAdd(){
                this.$router.push({path:'/rotation/add',query:{title:this.labelTitle,type:this.selectTab}})
            }
        }
    }
</script>

<style lang="scss" scoped>
    .rotation {
        padding: 24px;
    }

    .title-tabs {
        flex: 10;

        label {
            display: inline-block;
            font-size: 20px;
            font-weight: 600;
            height: 30px;
            margin-right: 40px;
            cursor: pointer;
        }

    }

    .new-ppt {
        flex: 1;
    }

    .rotation-box {
        margin-top: 20px;
    }

    // 动态绑定的class
    .select-tab {
        border-bottom: 2px solid #4458FE;
        color: #4458FE;
    }
</style>